html, body {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

.content-wrapper {
  background-color: black; }

.main_content {
  min-height: 100vh;
  padding: 0 10vw; }

@media (min-width: 768px) {
  .swiper-slide, swiper-slide-next {
    width: 400px !important; }
  .gear_slider_image {
    width: 400px;
    height: 400px;
    object-fit: contain; } }

.gear_slider_image_sm {
  width: 100%;
  padding: 20px;
  height: 400px;
  object-fit: contain; }

@media (min-width: 768px) {
  .main_content {
    padding: 0 10vw; } }

@media (max-width: 768px) {
  .main_content {
    padding: 0 4vw; } }

.home .home-first-section {
  background-image: url("/image/home/home-first-section-bg.png");
  background-size: cover;
  width: 100%;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align-all: center; }
  .home .home-first-section .home-section-start-btn {
    color: #ffffff;
    font-family: "HelveticaNeue-Medium";
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    width: 186px;
    height: 72px; }

.home .galery_sm_style {
  margin-top: 10vh; }

@media (min-width: 768px) {
  .home-first-section {
    height: 554px; }
    .home-first-section p {
      width: 35%;
      padding-top: 10vh;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 72px;
      font-weight: 700;
      line-height: 84px;
      text-align: center;
      letter-spacing: -1.285714px; }
  .home-second-section {
    height: 613px; }
    .home-second-section .home-second-section-title {
      padding-top: 87px;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      letter-spacing: -0.5px; } }

@media (max-width: 768px) {
  .home-first-section {
    padding-bottom: 11vh; }
    .home-first-section p {
      width: 100%;
      padding-top: 10vh;
      color: #ffffff;
      font-family: Montserrat;
      font-size: 44px;
      font-weight: 700;
      text-align: center;
      letter-spacing: -1.285714px; }
  .home-second-section {
    margin-top: 5vh; }
    .home-second-section .home-second-section-title {
      display: none; } }

.slide_background {
  background-repeat: no-repeat;
  background-size: contain;
  height: 350px;
  margin: 0;
  margin: 20px; }

.slick-slider {
  overflow: hidden; }

.about {
  text-align: -webkit-center;
  text-align: -moz-center; }
  .about .about-image {
    margin-bottom: 8vh;
    margin-top: 7vh; }
  .about .about-first-text {
    color: #ffffff;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.5px; }
  .about .about-second-text {
    color: #cccccc;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.1px; }

@media (min-width: 768px) {
  .about-image {
    width: 576px;
    height: 416px; }
  .about-first-text {
    width: 53%;
    font-size: 40px;
    text-align: center; }
  .about-second-text {
    width: 60%;
    text-align: center; } }

@media (max-width: 768px) {
  .about-image {
    width: 100%; }
  .about-first-text {
    width: 100%;
    text-align: left;
    font-size: 24px; }
  .about-second-text {
    width: 100%;
    text-align: left; } }

.navbar {
  width: 100%;
  height: 82px;
  background-color: black;
  padding: 18px 25px; }
  .navbar .navbar-title {
    text-align: left; }
    .navbar .navbar-title p {
      margin: 0;
      padding: 0; }
  .navbar .navbar-menu {
    width: 65%;
    justify-content: flex-end; }
    .navbar .navbar-menu a {
      padding: 0;
      margin: 0;
      cursor: pointer;
      padding-right: 15px;
      color: #ffffff !important;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px; }

@media (max-width: 768px) {
  .navbar-menu a {
    padding: 0;
    margin: 0;
    cursor: pointer;
    padding-right: 15px;
    color: #ffffff !important;
    font-family: Montserrat;
    font-size: 36px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px; } }

.footer {
  border-top: 1px solid rgba(35, 37, 39, 0.8); }

.footer-menu {
  width: 65%;
  justify-content: flex-end; }
  .footer-menu p {
    padding: 0;
    margin: 0;
    cursor: pointer;
    padding-right: 15px;
    color: rgba(130, 130, 130, 0.8);
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; }

@media (min-width: 768px) {
  .navbar-title {
    width: 30%; }
  .navbar-title-first {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px; }
  .navbar-title-second {
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; }
  .navbar_menu_icon {
    display: none; } }

@media (max-width: 768px) {
  .navbar-title {
    width: 70%; }
  .navbar-title-first {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    line-height: 10px; }
  .navbar-title-second {
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 8px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; }
  .navbar-menu {
    display: none; }
  .footer {
    height: auto !important; }
  .footer-menu p {
    padding-top: 2vh; } }

.ReactModal__Overlay {
  background-color: white !important; }

.mobile_nav_menu {
  display: block;
  height: 100%;
  padding-top: 10vh;
  text-align: left; }
  .mobile_nav_menu .mobile_nav_text {
    padding-top: 4vh;
    color: #ffffff;
    font-family: "HelveticaNeue-Bold";
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.3px; }

.nav_close_btn {
  background-image: url("/image/navbar/nav_close.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px; }

.slidemenunav {
  background-image: url("/image/navbar/menu-lines.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat; }

.sm_nav_parent {
  padding-top: 4vh;
  padding-left: 4vh;
  overflow: hidden;
  background: black;
  height: 100vh; }

.slider-footer {
  margin-top: 15vh; }

.application_text {
  text-align: -webkit-center;
  text-align: -moz-center;
  padding-top: 38px; }

.application-first-text {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px; }

.application-second-text {
  color: #cccccc;
  width: 45%;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px; }

@media (min-width: 768px) {
  .application-first-text {
    text-align: center;
    width: 33%; }
  .gear-public-image {
    width: 338px;
    height: 338px;
    object-fit: cover;
    margin: 20px; } }

@media (max-width: 768px) {
  .gear-public-image {
    width: 135px;
    height: 136px;
    object-fit: cover;
    margin: 10px; }
  .application-first-text {
    width: 90%;
    text-align: left; } }

.application-image-group {
  text-align: -webkit-center;
  text-align: -moz-center; }

@media (min-width: 768px) {
  .application {
    padding-bottom: 10vh; } }

@media (max-width: 768px) {
  .application {
    padding-bottom: 4vh; } }

@media (min-width: 768px) {
  .products-image-group {
    text-align: right; }
    .products-image-group img {
      width: 277px;
      height: 277px;
      object-fit: cover; }
    .products-image-group .gear-public-image-more {
      width: 277px;
      height: 277px;
      color: white;
      object-fit: cover; } }

@media (max-width: 768px) {
  .products-image-group {
    text-align: center; }
    .products-image-group img {
      width: 135px;
      height: 136px;
      object-fit: cover;
      margin: 10px; }
    .products-image-group .gear-public-image-more {
      width: 135px;
      height: 136px;
      object-fit: cover;
      margin: 10px; }
  .products-first-text {
    padding-left: 5vw !important; }
  .products-sm-text {
    padding-left: 5vw;
    padding-top: 4vh; } }

.products {
  padding-top: 57px; }

.products-first-text {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.5px;
  text-align: left; }

.products-second-text {
  color: #cccccc;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.1px; }

@media (min-width: 768px) {
  .pride-image {
    width: 585px;
    height: 340px;
    object-fit: cover;
    margin-right: 10vw; }
  .pride-top-text {
    text-align: left;
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 300;
    line-height: 32px; }
  .pride-down-text {
    padding-top: 16vh; } }

@media (max-width: 768px) {
  .pride-image {
    width: 100%;
    object-fit: contain; }
  .pride-top-text {
    padding-top: 4vh;
    color: #cccccc;
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px; }
  .pride-down-text {
    padding-top: 6vh; } }

.pride {
  padding-top: 65px; }

.pride-down-text-title {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; }

.pride-down-text-content {
  color: #cccccc;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  line-height: 24px; }
